import React from 'react';
import { Helmet } from 'react-helmet';
import Head from '../components/head';
import Layout from '../components/layout';
import ContactForm from '../components/contactForm';

const ContactPage = () => {
  const baseSiteUrl = 'https://authorseanallen.com';
  const PAGE = "Contact"
  return (
    <Layout>
      <Head title={PAGE} />
      <Helmet>
        <meta name="description"
          content="Contact science fiction fantasy author Sean Allen" />
        <link rel="canonical" href={`${baseSiteUrl}/contact`} />
      </Helmet>
      <div className="Contact hero dark">
        <div className="page-header-info">
          <h3 className="page-title">Contact Sean</h3>
          <hr />
        </div>
        <div className="content">
          <section className="form-instructions">
            <p>
              <span className="highlight-yellow">Sean is always delighted to
                hear from honest to goodness, real-life people</span>, since
              he spends far too much time consorting with the imaginary folks
              he makes up in his head.
            </p>
            <p>
              You can drop Sean a line using the contact form on this page.
              <span className="highlight-yellow"> He does his best to answer
                every message, but please understand that it’s not always possible,
                depending on his writing, family, and business schedules</span>.
              While criticism is certainly a part of the writing game,
              Sean appreciates a kind hello or good-natured question the most.
            </p>
            <p>
              Speaking of inquiries, unfortunately, <span className="highlight-yellow">
                Sean can’t give you feedback on your manuscript</span>. If you're a fellow word
              nerd, there are plenty of groups out there for writers, and he encourages you
              to find one you like and join up. You’ll get plenty of eyes (and opinions) on
              your work, and that’s a good thing. You can also find posts on Sean’s
              approach to writing on his blog from time to time. Just look for the category
              called <i><span className="highlight-yellow">writing process</span></i>,
              give it a click, and it’ll be time to disco (or read, at least).
            </p>
          </section>
          <ContactForm />
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage;