import React, { useState } from 'react';
import Recaptcha from 'react-google-recaptcha';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const ContactForm = () => {

  const RECAPTCHA_KEY = process.env.SITE_RECAPTCHA_KEY
  if (typeof RECAPTCHA_KEY === 'undefined') {
    throw new Error(`
  Env var GATSBY_APP_SITE_RECAPTCHA_KEY is undefined! 
  You probably forget to set it in your Netlify build environment variables. 
  Make sure to get a Recaptcha key at https://www.netlify.com/docs/form-handling/#custom-recaptcha-2-with-your-own-settings
  Note this demo is specifically for Recaptcha v2
  `)
  }

  function encode (data) {
    return Object.keys(data)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [msg, setMsg] = useState('');
  const [msgLength, setMsgLength] = useState(0);


  const recaptchaRef = React.createRef();

  const handleChange = (e) => {
    switch (e.target.name) {
      case 'name':
        setName(e.target.value);
        break;

      case 'email':
        setEmail(e.target.value);
        break;

      case 'message':
        setMsg(e.target.value);
        setMsgLength(e.target.value.length);
        break;

      default:
        break;
    }

  }

  const clearState = () => {
    setMsgLength(0);
    setName('');
    setEmail('');
    setMsg('');
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const recaptchaValue = recaptchaRef.current.getValue();

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        'g-recaptcha-response': recaptchaValue,
        name: name,
        email: email,
        message: msg
      }),
    })
      .then((result) => {
        console.log('post results:', result);
        if (result.status === 200) {
          fireSuccessNotice();
        } else {
          fireGenericFailureNotice();
        }
        resetRecaptcha();
      })
      .catch((error) => fireGenericFailureNotice())
  }

  const fireSuccessNotice = () => {
    const notice = withReactContent(Swal);
    notice.fire({
      icon: 'success',
      title: 'Aw, yeah...',
      background: '#43209d',
      confirmButtonColor: '#F3BC0B',
      width: '40rem',
      scrollbarPadding: true,
      text: `Thanks for reaching out. Sean will be in touch as 
        soon as he can!`,
    }).then((result) => {
      if (result.isConfirmed) {
        clearState();
      }
    })
  }

  const fireGenericFailureNotice = () => {
    const notice = withReactContent(Swal);
    notice.fire({
      icon: 'error',
      title: 'Oops...',
      background: '#43209d',
      confirmButtonColor: '#F3BC0B',
      width: '40rem',
      scrollbarPadding: true,
      text: `Something went wrong. Please try again.`,
    })
  }

  const resetRecaptcha = () => {
    recaptchaRef.current.reset();
  }

  return (
    <section className="ContactForm">
      <div className="flex-spacer">
        <form
          name="contact-recaptcha"
          subject="Website Contact Form"
          method="post"
          data-netlify="true"
          data-netlify-recaptcha="true"
          onSubmit={handleSubmit}
        >
          <noscript>
            <p>This form won’t work with Javascript disabled</p>
          </noscript>
          <p>
            <label>
              Your name <span className="required-asterisk">*</span>
              <br />
              <input
                type="text"
                name="name"
                required
                value={name}
                onChange={handleChange} />
            </label>
          </p>
          <p>
            <label>
              Your email <span className="required-asterisk">*</span>
              <br />
              <input
                type="email"
                name="email"
                required
                value={email}
                onChange={handleChange} />
            </label>
          </p>
          <p>
            <label>
              Message <span className="required-asterisk">*</span>
              <br />
              <textarea
                name="message"
                maxLength="2500"
                required
                value={msg}
                onChange={handleChange} />
            </label>
            <span className="msg-char-count">{msgLength} of 2500 max characters</span>
          </p>
          <Recaptcha
            className="captcha-container full"
            ref={recaptchaRef}
            sitekey={RECAPTCHA_KEY} />
          <Recaptcha
            className="captcha-container mobile"
            size='compact'
            ref={recaptchaRef}
            sitekey={RECAPTCHA_KEY} />
          <p>
            <button className="btn purple" type="submit">Submit</button>
          </p>
        </form>
      </div>
    </section>
  )
}

export default ContactForm;